<template>
  <div>
    <div class="ui-type-caption text-gray-600 text-uppercase">
      {{ parentCategory() }}
    </div>
    <div>{{ category() }}</div>
  </div>
</template>

<script>
import get from "lodash/get";

import { getTranslation } from "../../../../helpers/common";
export default {
  name: "ProductTableVariantCategory",

  methods: {
    parentCategory() {
      const lang = this.$i18n.locale;
      return getTranslation(
        get(this.data, "productproducer.category.parent.translations", {}),
        lang
      );
    },

    category() {
      const lang = this.$i18n.locale;
      return getTranslation(
        get(this.data, "productproducer.category.translations", {}),
        lang
      );
    },
  },
};
</script>
